export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://aun.vercel.app/',
	},
	{
		id: 2,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/aun143',
	},
	{
		id: 3,
		name: 'Twitter',
		icon: 'twitter',
		url: 'https://x.com/AunAfzaal',
	},
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/m-aun7954',
	},
];
